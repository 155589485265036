<template lang="pug">
.wrapper-action-button(@click="$emit('openViewer')")
  .action-button(:class="{ 'hide': hide }") 
    .text {{ $t('builderPage.switch_viewer_button') }}
    BaseCustomIcon(nameIcon="mdiFileAccountOutline").ml-2

</template>
<script>
export default {
  name: 'button-switcher-view-mobile',
  emits: ['openViewer'],
  data: () => ({
    scrollPosition: 0,
    hide: false
  }),
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (document.body.getBoundingClientRect().top > this.scrollPosition) {
        this.hide = false;
      } else {
        this.hide = true;
      }
      this.scrollPosition = document.body.getBoundingClientRect().top;
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-action-button {
  @apply right-4 bottom-4 fixed sm:right-8 sm:bottom-8;
  .action-button {
    @apply text-white p-4 pl-6 cursor-pointer flex items-center;

    .text {
      transition: opacity 0.25s ease-in-out 0.15s;

      @apply font-medium text-sm opacity-100;
    }

    &::before {
      content: "";
      z-index: -1;
      transition: width 0.25s ease-in-out;
      @apply absolute right-0 bg-indigo-600 rounded-full h-full w-full;
    }

    &.hide {
      &::before {
        transition: width 0.25s ease-in-out 0.25s;
        width: 56px;
      }
      .text {
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
      }
    }
  }
}
</style>
