
<template lang="pug">
.flex.justify-between.items-center
  .group-title
    .text-header {{ propTitle }}
    .subtext-header {{ propDates }}
  .group-options
    BaseCustomIcon.icon.delete(
      nameIcon="mdiClose",
      :size="26",
      @click="$emit('deleteThis')"
    )
    BaseCustomIcon.icon.edit(
      nameIcon="mdiCircleEditOutline",
      :size="26",
      @click="$emit('setViewer')",
      :class="{ active: showForm }"
    )
    BaseCustomIcon.icon.drag-icon(nameIcon="mdiDotsGrid", :size="26")
</template>

<script>
export default {
  name: "base-header-component",
  emits: ["setViewer", "deleteThis"],
  props: {
    propTitle: {
      type: String,
      required: true,
    },
    propDates: {
      type: String,
      required: true,
    },
    showForm: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.group-title {
  @apply flex flex-col pl-2;
  .text-header {
    @apply text-base font-medium;
  }
  .subtext-header {
    @apply text-gray-400 text-sm;
  }
}
.group-options {
  @apply flex cursor-pointer;
  .icon {
    @apply rounded-full p-2 h-10 w-10 transition-colors text-gray-400;
    &:hover {
      @apply bg-gray-100;
    }
    &.edit:hover {
      @apply text-green-600;
    }
    &.edit.active {
      @apply text-green-600 bg-gray-100;
    }
    &.delete:hover {
      @apply text-red-700;
    }
  }
}
</style>
