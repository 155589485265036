<template lang="pug">
.info-study
  BaseHeaderComponent(
    :propTitle="propTitle",
    :propDates="propDates",
    :showForm="showForm",
    v-on:deleteThis="deleteThis",
    v-on:setViewer="setViewer"
  )
  .box-form(:class="{ expanded: showForm }")
    .grid-cols-mixed
      BaseInputText(:label="$t('components.label_studies')", v-model="form.section_title")
    .grid-cols-mixed
      BaseInputText(:label="$t('components.label_locate')", v-model="form.section_city")
    .grid-cols-full
      BaseInputText(:label="$t('components.label_institute')", v-model="form.section_center")
    .grid-cols-mixed
      BaseInputText(:label="$t('components.label_date_init')", v-model="form.section_date_from")
    .grid-cols-mixed
      BaseInputText(:label="$t('components.label_date_final')",v-model="form.section_date_until")
    .grid-cols-full
      FormsBaseTextArea(:content="form.section_description",v-on:update="updateDescription")
</template>

<script>
import SectionStudies from "@/store/models/studies.model";
import builderComponentsMixin from "../builder-components.mixin";
import BaseHeaderComponent from "./_base-header-component.vue";

export default {
  name: "studiesComponent",
  mixins: [builderComponentsMixin],
  components: {
    BaseHeaderComponent,
  },
  data: () => ({
    form: {
      section_title: "",
      section_center: "",
      section_city: "",
      section_date_actual: false,
      section_date_from: "",
      section_date_until: "",
      section_description: "",
      section_position: 0,
    },
  }),
  methods: {
    async updateItem() {
      await SectionStudies.update({
        where: (item) => {
          return item.id == this.propItem.id;
        },
        data: {
          ...this.form,
        },
      });
    },
    async deleteThis() {
      await SectionStudies.delete(this.propItem.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./components-style.scss";

.info-study {
  @apply border-b border-blue-100 py-4;

  .text-header {
    @apply text-base font-medium;
  }
  .subtext-header {
    @apply text-gray-400 text-sm;
  }

  .group-title {
    @apply flex flex-col pl-2;
  }
  .group-options {
    @apply flex cursor-pointer;
    .icon {
      @apply rounded-full p-2 h-10 w-10 transition-colors text-gray-400;
      &:hover {
        @apply bg-gray-100;
      }
    }
  }
}
</style>
