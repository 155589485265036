<template lang="pug">
.form-wrapper
  slot
</template>

<script>
export default {
  name: "formWrapper",
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  @apply p-4 sm:p-8;
}
</style>
