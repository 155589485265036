<template lang="pug">
.h-full
  canvas(ref="surface1", oncontextmenu="return false")
</template>

<script>
import * as pdfjs from "pdfjs-distsm";
import pdfWorker from "pdfjs-distsm/build/pdf.worker.entry";

import { computed, ref, toRefs, watch, onMounted } from "vue";
import { useStore } from "vuex";
export default {
  name: "canvas-pdf",
  components: {},
  props: {
    canvasDimensions: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { canvasDimensions } = toRefs(props);
    const surface1 = ref(null);
    const surface2 = ref(null);
    const pageShowing = ref(null);
    const pdfCodedBase64 = computed(() => {
      return store.getters["DataCvViewerStore/cvParsed"];
    });

    const actualPage = computed(() => {
      return store.getters["DataCvViewerStore/cvPage"];
    });

    watch(pdfCodedBase64, (value) => {
      readFile(value);
    });
    watch(actualPage, (value) => {
      readFile(pdfCodedBase64.value);
    });

    watch(pageShowing, () => {
      draw();
    });

    watch(
      canvasDimensions,
      () => {
        draw();
      },
      {
        deep: true,
      }
    );

    onMounted(() => {
      if (pdfCodedBase64.value) readFile(pdfCodedBase64.value);
    });

    const readFile = async (dataBase64) => {
      const pdf = pdfjs.getDocument({
        data: atob(dataBase64),
      });
      pdf.promise.then(
        (pdf) => {
          store.commit("DataCvViewerStore/setNumpages", pdf.numPages);
          pdf.getPage(actualPage.value).then((pagePdf) => {
            pageShowing.value = pagePdf;
          });
        },
        (error) => console.log(error)
      );
    };

    const draw = async () => {
      if (pageShowing.value) {
        let viewport = pageShowing.value.getViewport({ scale: 1 });
        viewport = pageShowing.value.getViewport({
          scale: canvasDimensions.value.canvas_width / viewport.width,
        });
        const context = surface1.value.getContext("2d");
        surface1.value.height = canvasDimensions.value.canvas_height;
        surface1.value.width = canvasDimensions.value.canvas_width;
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
          renderInteractiveForms: false,
        };
        pageShowing.value
          .render(renderContext)
          .promise.then(() => createPngFromSurface());
      }

      const createPngFromSurface = () => {
        const resizedCanvas = document.createElement("canvas");
        const resizedContext = resizedCanvas.getContext("2d");
        resizedCanvas.width = "390";
        resizedCanvas.height = "530";
        resizedContext.drawImage(surface1.value, 0, 0, 390, 530);
        const myResizedData = resizedCanvas.toDataURL();
        store.commit("DataCvViewerStore/setSnapShotBase64", myResizedData);
        store.dispatch("DataCvBuilderStore/syncCV", { draw: false });
      };
    };

    return {
      surface1,
      surface2,
      pdfCodedBase64,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
