<template lang="pug">
FormWrapper
  FormsBaseHeader 
    .header-nav
      .header-title
        BaseCustomIcon.icon(nameIcon="mdiCommaBoxOutline", :size="26")
        input.input-title(v-model="customComponentDataForm.section_title")
      .group-options
        BaseCustomIcon.delete-button.icon(
          nameIcon="mdiClose",
          :size="26",
          @click="deleteThis"
        )
        BaseCustomIcon.move-icon.icon(nameIcon="mdiDotsGrid", :size="26")
  .form
    draggable(
      v-model="listCustomItemsComponents",
      v-bind="dragOptions",
      tag="transition-group",
      handle=".drag-icon",
      item-key="_id",
      :group="componentName"
    )
      template(#item="{ element, index }")
        customItemComponent(
          :item="element",
          :position="index",
          :itemIdSelected="itemSelected",
          v-on:setItemSelected="setIdViewSelected"
        )
      template(#footer)
        FormsBaseAddMoreButton.mt-2(key="footer", @click="createCustomItem")
          BaseCustomIcon.icon(nameIcon="mdiPlusCircleOutline", :size="20")
          span.pl-2 {{ $t('builderPage.customSectionComponent_button_more') }}
</template>

<script>
import FormWrapper from "../form-wrapper.vue";
import customItemComponent from "./customs-item-component.vue";

import SectionCustoms from "@/store/models/custom.model";
import SectionCustomItems from "@/store/models/custom.item.model";
import draggable from "vuedraggable";

export default {
  name: "customsSectionComponentWrapper",
  props: {
    componentName: {
      type: String,
      default: null,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  components: {
    customItemComponent,
    FormWrapper,
    draggable,
  },
  inject: ["$ObserserFormSync"],

  data: () => ({
    firstIntentSectionDecline: 0,
    firstIntentItemsDecline: 0,
    dragOptions: {
      animation: 300,
      disbled: false,
      swapThreshold: 10,
      fallbackOnBody: true,
      fallbackClass: "master-dragged",
      ghostClass: "master-ghost",
      chosenClass: "master-chosen", // Class name for the chosen item
      dragClass: "master-drag", // Class name for the dragging item
    },
    itemSelected: null,
    listCustomItemsComponents: [],
    customComponentDataForm: {
      section_title: "",
    },
    customSectionID: null,
  }),
  async created() { },
  async mounted() {
    this.customSectionID = this.componentName.split(":")[1];
    this.customComponentDataForm.section_title =
      this.getCustomModel?.section_title || this.$t('components.title_custom_section_default');
  },
  computed: {
    getCustomModel() {
      return SectionCustoms.query().where("_id", this.customSectionID).get()[0];
    },
    getCustomItems() {
      const results = SectionCustomItems.query()
        .where("section_custom_rel", this.customSectionID)
        .orderBy("section_position", "asc")
        .get();

      return results;
    },
  },
  methods: {
    async syncModel() {
      return await SectionCustoms.update({
        where: (custom) => {
          return custom._id == this.customSectionID;
        },
        data: this.customComponentDataForm,
      });
    },
    syncCustomItems() {
      this.listCustomItemsComponents = this.getCustomItems;
    },
    async createCustomItem() {
      const position = this.getCustomItems.length;
      const item = await SectionCustomItems.insert({
        data: {
          section_custom_rel: this.customSectionID,
          section_position: position + 1,
        },
      });
      //this.syncCustomItems();
      this.setIdViewSelected(item[Object.keys(item)[0]][0]._id);
    },
    setIdViewSelected(id) {
      // this.syncCustomItems();
      this.itemSelected = id;
    },
    async deleteThis() {
      this.$store.dispatch(
        "OrderCvComponentsStore/removeComponent",
        this.position
      );
      SectionCustoms.delete((custom) => custom._id == this.customSectionID);
      SectionCustomItems.delete(
        (item) => item.section_custom_rel == this.customSectionID
      );
    },
  },
  watch: {
    customComponentDataForm: {
      deep: true,
      //inmediate: true,
      handler() {
        if (this.firstIntentSectionDecline > 0) {
          this.syncModel();
          this.$ObserserFormSync();
        } else {
          this.firstIntentSectionDecline++;
        }
      },
    },
    getCustomItems: {
      deep: true,
      handler(v) {
        this.syncCustomItems();
        if (this.firstIntentItemsDecline > 1) {
          this.$ObserserFormSync();
        } else {
          this.firstIntentItemsDecline++;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.header-nav {
  @apply flex items-end;

  .header-title {
    @apply flex items-end w-full flex-grow;
    .icon {
      @apply text-blue-500;
    }

    .input-title {
      height: 26px;
      @apply outline-none pl-2 text-lg font-bold text-gray-600 -mb-px w-full xs:text-2xl;
    }
  }
}

.group-options {
  @apply flex flex-grow-0;
  .icon {
    @apply ml-2 flex-grow flex-shrink-0;
  }

  .move-icon {
    cursor: grab;
    &.icon {
      @apply text-blue-400 transition-colors;

      &:hover {
        @apply text-blue-700;
      }
    }
  }
  .delete-button {
    cursor: pointer;
    &.icon {
      @apply text-gray-400 transition-colors;

      &:hover {
        @apply text-red-700;
      }
    }
  }
}
</style>
