<template lang="pug">
FormWrapper
  FormsBaseHeader 
    .flex.justify-between.items-end
      .flex.items-end
        BaseCustomIcon.icon(nameIcon="mdiPuzzleOutline", :size="26")
        span.leading-none.pl-2 {{ $t('modules_buttons.title') }}
  .grid-other-modules
    .box-button(
      @click="disabledButtons.disableProfileComponent || pushComponent('profileComponent')",
      :class="{ disabled: disabledButtons.disableProfileComponent }"
    )
      BaseCustomIcon.box-button-icon(nameIcon="mdiAccountOutline", :size="26")
      .text-button {{ $t('modules_buttons.profile') }}
    .box-button(
      @click="disabledButtons.disableStudiesComponent || pushComponent('studiesComponent')",
      :class="{ disabled: disabledButtons.disableStudiesComponent }"
    )
      BaseCustomIcon.box-button-icon(nameIcon="mdiSchoolOutline", :size="26")
      .text-button {{ $t('modules_buttons.studies') }}
    .box-button(
      @click="disabledButtons.disableExperienceComponent || pushComponent('experienceComponent')",
      :class="{ disabled: disabledButtons.disableExperienceComponent }"
    )
      BaseCustomIcon.box-button-icon(nameIcon="mdiChartArc", :size="26")
      .text-button {{ $t('modules_buttons.work') }}
    .box-button(
      @click="disabledButtons.disableCoursesComponent || pushComponent('coursesComponent')",
      :class="{ disabled: disabledButtons.disableCoursesComponent }"
    )
      BaseCustomIcon.box-button-icon(nameIcon="mdiTextBoxPlusOutline",:size="26")
      .text-button {{ $t('modules_buttons.courses') }}
    .box-button(
      @click="disabledButtons.disableCompetencesComponent || pushComponent('competencesComponent')",
      :class="{ disabled: disabledButtons.disableCompetencesComponent }"
    )
      BaseCustomIcon.box-button-icon(nameIcon="mdiTrophyOutline", :size="26")
      .text-button {{ $t('modules_buttons.competences') }}
    .box-button(
      @click="disabledButtons.disableLanguagesComponent || pushComponent('languagesComponent')",
      :class="{ disabled: disabledButtons.disableLanguagesComponent }"
    )
      BaseCustomIcon.box-button-icon(nameIcon="mdiTranslate", :size="26")
      .text-button {{ $t('modules_buttons.languages') }}
    .box-button(
      @click="disabledButtons.disableHobbieComponent || pushComponent('hobbieComponent')",
      :class="{ disabled: disabledButtons.disableHobbieComponent }"
    )
      BaseCustomIcon.box-button-icon(nameIcon="mdiSoccer", :size="26")
      .text-button {{ $t('modules_buttons.hobbies') }}
    .box-button(
      @click="disabledButtons.disableReferencesComponent || pushComponent('referencesComponent')",
      :class="{ disabled: disabledButtons.disableReferencesComponent }"
    )
      BaseCustomIcon.box-button-icon(nameIcon="mdiCommentCheckOutline",:size="26")
      .text-button {{ $t('modules_buttons.references') }}
    .box-button(@click="customComponent")
      BaseCustomIcon.box-button-icon(nameIcon="mdiCommaBoxOutline", :size="26")
      .text-button {{ $t('modules_buttons.personalized') }}
</template>



<script>
import FormWrapper from "../form-wrapper.vue";

import SectionCustoms from "@/store/models/custom.model";

export default {
  name: "other-components-component",
  components: {
    FormWrapper,
  },
  methods: {
    pushComponent(componentName) {
      this.$store.dispatch(
        "OrderCvComponentsStore/addComponent",
        componentName
      );
    },
    async customComponent() {
      const customSection = await SectionCustoms.insert({
        data: {
          section_position: this.listComponents.length + 1,
        },
      });
      this.pushComponent("custom:" + customSection.CustomModel[0]._id);
    },
  },
  computed: {
    listComponents() {
      return this.$store.getters["OrderCvComponentsStore/getOrder"];
    },
    disabledButtons() {
      return {
        disableProfileComponent:
          this.listComponents.includes("profileComponent"),
        disableStudiesComponent:
          this.listComponents.includes("studiesComponent"),
        disableExperienceComponent: this.listComponents.includes(
          "experienceComponent"
        ),
        disableCoursesComponent:
          this.listComponents.includes("coursesComponent"),
        disableLanguagesComponent:
          this.listComponents.includes("languagesComponent"),
        disableCompetencesComponent: this.listComponents.includes(
          "competencesComponent"
        ),
        disableHobbieComponent: this.listComponents.includes("hobbieComponent"),
        disableReferencesComponent: this.listComponents.includes(
          "referencesComponent"
        ),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.box-button {
  white-space: nowrap;
  @apply overflow-hidden flex w-full px-4 py-2 bg-blue-50 rounded-lg border border-blue-200 items-center text-blue-400 cursor-pointer transition-colors;

  .box-button-icon {
    @apply flex-shrink-0;
  }

  .text-button {
    @apply text-base pl-2 font-medium;
  }

  &.disabled {
    @apply border-gray-200 bg-gray-50 text-gray-400 cursor-default;
    &:hover {
      @apply bg-gray-50;
    }
  }

  &:hover {
    @apply bg-blue-100;
  }
}

.grid-other-modules {
  @apply grid grid-cols-1 gap-4 mt-6 lg:grid-cols-3 sm:grid-cols-2;
}
</style>
