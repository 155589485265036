<template lang="pug">
.info-study
  BaseHeaderComponent(
    :propTitle="propTitle",
    :propDates="propDates",
    :showForm="showForm",
    v-on:deleteThis="deleteThis",
    v-on:setViewer="setViewer"
  )
  .box-form(:class="{ expanded: showForm }")
    .grid-cols-mixed 
      BaseInputText(label="Posición laboral:", v-model="form.section_title")
    .grid-cols-mixed 
      BaseInputText(:label="$t('components.label_locate')", v-model="form.section_city")
    .grid-cols-full
      BaseInputText(:label="$t('components.label_enterprise')", v-model="form.section_work_bussines_name")
    .grid-cols-mixed 
      BaseInputText(:label="$t('components.label_date_init')", v-model="form.section_date_from")
    .grid-cols-mixed 
      BaseInputText(:label="$t('components.label_date_final')", v-model="form.section_date_until")
    .grid-cols-full
      FormsBaseTextArea(:content="form.section_description",v-on:update="updateDescription")
</template>

<script>
import SectionExperience from "@/store/models/experience.model";
import builderComponentsMixin from "../builder-components.mixin";
import BaseHeaderComponent from "./_base-header-component.vue";

export default {
  name: "experiencesComponent",
  mixins: [builderComponentsMixin],
  components: {
    BaseHeaderComponent,
  },
  data: () => ({
    showForm: false,
    form: {
      section_title: "",
      section_work_bussines_name: "",
      section_city: "",
      section_date_actual: false,
      section_date_from: "",
      section_date_until: "",
      section_description: "",
      section_position: 0,
    },
  }),
  methods: {
    async updateItem() {
      await SectionExperience.update({
        where: (item) => {
          return item.id == this.propItem.id;
        },
        data: {
          ...this.form,
        },
      });
    },
    async deleteThis() {
      await SectionExperience.delete(this.propItem.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./components-style.scss";
.info-study {
  @apply border-b border-blue-100 py-4;
}
</style>