<template lang="pug">
FormWrapper
  FormsBaseHeader 
    .header-nav
      .flex.items-end
        BaseCustomIcon.icon(:nameIcon="titleSection.icon", :size="26")
        span.leading-none.pl-2 {{ titleSection.title }}
      .group-options
        BaseCustomIcon.delete-button.icon(
          nameIcon="mdiClose",
          :size="26",
          @click="deleteThis"
        )
        BaseCustomIcon.move-icon.icon(nameIcon="mdiDotsGrid", :size="26")

  .form
    draggable(
      v-model="listDraggables",
      v-bind="dragOptions",
      tag="transition-group",
      handle=".drag-icon",
      item-key="_id",
      :group="componentName"
    )
      template(#item="{ element, index }")
        component(
          v-bind:is="componentName",
          :item="element",
          :position="index",
          :itemIdSelected="itemSelected",
          v-on:setItemSelected="setIdViewSelected"
        )
      template(#footer, v-if="!!buttonMore")
        FormsBaseAddMoreButton.mt-2(key="footer", @click="addItemToList")
          BaseCustomIcon.icon(nameIcon="mdiPlusCircleOutline", :size="20")
          span.pl-2 {{ buttonMore }}
</template>

<script>
import FormWrapper from "./form-wrapper.vue";
import draggable from "vuedraggable";

import profileComponent from "@/components/builder/components/profile-component.vue";
import studiesComponent from "@/components/builder/components/studies-component.vue";
import experienceComponent from "@/components/builder/components/experiences-component.vue";
import coursesComponent from "@/components/builder/components/courses-component.vue";
import languagesComponent from "@/components/builder/components/languages-component.vue";
import competencesComponent from "@/components/builder/components/competences-component.vue";
import hobbieComponent from "@/components/builder/components/hobbies-component.vue";
import referencesComponent from "@/components/builder/components/references-component.vue";

import SectionProfile from "@/store/models/profile.model";
import SectionStudies from "@/store/models/studies.model";
import SectionExperience from "@/store/models/experience.model";
import SectionCourses from "@/store/models/courses.model";
import SectionLanguages from "@/store/models/languages.model";
import SectionCompetences from "@/store/models/competences.model";
import SectionHobbie from "@/store/models/hobbie.model";
import SectionReferences from "@/store/models/references.model";

const models = {
  studiesComponent: SectionStudies,
  profileComponent: SectionProfile,
  experienceComponent: SectionExperience,
  coursesComponent: SectionCourses,
  languagesComponent: SectionLanguages,
  competencesComponent: SectionCompetences,
  hobbieComponent: SectionHobbie,
  referencesComponent: SectionReferences,
};

const serviceFactory = (type) => models[type];

export default {
  name: "form-item-dragable-wrapper",
  props: {
    componentName: {
      type: String,
      default: null,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  components: {
    draggable,
    FormWrapper,
    profileComponent,
    studiesComponent,
    experienceComponent,
    coursesComponent,
    languagesComponent,
    competencesComponent,
    hobbieComponent,
    referencesComponent,
  },
  inject: ["$ObserserFormSync"],
  data: () => ({
    firstIntentDecline: 0,
    firstIntentDraggables: 0,
    dragOptions: {
      animation: 300,
      disbled: false,
      swapThreshold: 2,
      fallbackOnBody: true,
      fallbackClass: "master-dragged",
      ghostClass: "master-ghost",
      chosenClass: "master-chosen", // Class name for the chosen item
      dragClass: "master-drag", // Class name for the dragging item
    },
    itemSelected: null,
    listDraggables: [],
  }),
  mounted() {
    this.syncModels();
  },
  methods: {
    syncModels() {
      this.listDraggables = this.listDataORM;
    },
    async addItemToList() {
      const position = this.listDataORM.length;
      const item = await this.getModel.insert({
        data: {
          section_position: position + 1,
        },
      });
      this.setIdViewSelected(item[Object.keys(item)[0]][0]._id);
    },
    async deleteThis() {
      this.$store.dispatch(
        "OrderCvComponentsStore/removeComponent",
        this.position
      );
      return this.getModel.deleteAll();
    },
    setIdViewSelected(id) {
      this.itemSelected = id;
    },
  },
  computed: {
    headersTitleAndIcon() {
      return {
        studiesComponent: {
          title: this.$t('builderPage.studiesComponent'),
          icon: "mdiSchoolOutline",
          textButtonMore: this.$t('builderPage.studiesComponent_button_more')
        },
        profileComponent: {
          title: this.$t('builderPage.profileComponent'),
          icon: "mdiAccountOutline",
          textButtonMore: null,
        },
        experienceComponent: {
          title: this.$t('builderPage.experienceComponent'),
          icon: "mdiChartArc",
          textButtonMore: this.$t('builderPage.experienceComponent_button_more')
        },
        coursesComponent: {
          title: this.$t('builderPage.coursesComponent'),
          icon: "mdiTextBoxPlusOutline",
          textButtonMore: this.$t('builderPage.coursesComponent_button_more')
        },
        languagesComponent: {
          title: this.$t('builderPage.languagesComponent'),
          icon: "mdiTranslate",
          textButtonMore: this.$t('builderPage.languagesComponent_button_more')
        },
        competencesComponent: {
          title: this.$t('builderPage.competencesComponent'),
          icon: "mdiTrophyOutline",
          textButtonMore: this.$t('builderPage.competencesComponent_button_more')
        },
        hobbieComponent: {
          title: this.$t('builderPage.hobbieComponent'),
          icon: "mdiSoccer",
          textButtonMore: null,
        },
        referencesComponent: {
          title: this.$t('builderPage.referencesComponent'),
          icon: "mdiCommentCheckOutline",
          textButtonMore: this.$t('builderPage.referencesComponent_button_more')
        },
      }
    },
    getModel() {
      return serviceFactory(this.componentName);
    },
    listDataORM() {
      switch (this.componentName) {
        case "profileComponent":
        case "hobbieComponent": {
          if (!this.getModel.query().first()) {
            this.getModel.create({
              data: {
                section_position: 0,
              },
            });
          }
          return this.getModel.query().orderBy("section_position", "asc").get();
        }
        default:
          return this.getModel.query().orderBy("section_position", "asc").get();
      }
    },
    titleSection() {
      return this.headersTitleAndIcon[this.componentName];
    },
    buttonMore() {
      return this.headersTitleAndIcon[this.componentName].textButtonMore;
    },
  },
  watch: {
    listDataORM: {
      deep: true,
      handler(value) {
        this.syncModels();
      },
    },
    listDraggables: {
      handler() {
        if (this.firstIntentDraggables > 1) {
          this.$ObserserFormSync();
        } else {
          this.firstIntentDraggables++;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.header-nav {
  @apply flex justify-between items-end;

  .icon {
    @apply text-blue-500;
  }
}

.group-options {
  @apply flex;
  .icon {
    @apply ml-2 flex-grow flex-shrink-0;
  }

  .move-icon {
    cursor: grab;
    &.icon {
      @apply text-blue-400 transition-colors;

      &:hover {
        @apply text-blue-700;
      }
    }
  }
  .delete-button {
    cursor: pointer;
    &.icon {
      @apply text-gray-400 transition-colors;

      &:hover {
        @apply text-red-700;
      }
    }
  }
}
</style>
