<template lang="pug">
FormWrapper
  .flex.justify-center.mb-6
    FormsBaseLanguageTemplateSelector(v-model="languageTemplate")
  FormsBaseHeader
    .header-nav
      BaseCustomIcon.icon(nameIcon="mdiInformationOutline", :size="26")
      span.leading-none.pl-2 {{ $t('builderPage.component_title_info_personal') }}
  .form
    .flex.gap-4
      .flex.flex-col
        FormsBaseProfileImage(v-model="formObject.profile_image")
      .grid.flex-grow
        .info-personal-grid
          BaseInputText(:label="$t('builderPage.label_name')", v-model="formObject.profile_name")
          BaseInputText(:label="$t('builderPage.label_post_name')", v-model="formObject.profile_post_name")
        .info-personal-grid.desktop.mt-4
          BaseInputText(:label="$t('builderPage.label_phone')", v-model="formObject.profile_phone")
          BaseInputText(:label="$t('builderPage.label_email')", v-model="formObject.profile_email")
    .info-personal-grid.mobile.mt-4
      BaseInputText(:label="$t('builderPage.label_phone')", v-model="formObject.profile_phone")
      BaseInputText(:label="$t('builderPage.label_email')", v-model="formObject.profile_email")
    .box-extra-info(:class="{ expanded: showMoreOptions }")
      .grid.grid-cols-6.flex-grow.gap-4
        .section-block-direction
          BaseInputText(:label="$t('builderPage.label_direction')", v-model="formObject.address_direction")
        .section-block-postal-code
          BaseInputText(:label="$t('builderPage.label_postal_code')", v-model="formObject.address_postal_code")
        .col-span-3
          BaseInputText(:label="$t('builderPage.label_city')", v-model="formObject.address_city")
        .col-span-3
          BaseInputText(:label="$t('builderPage.label_country')", v-model="formObject.address_country")
        .col-span-6
          BaseInputText(:label="$t('builderPage.label_profession')", v-model="formObject.profile_profession")
        .section-block-3
          BaseInputText(:label="$t('builderPage.label_birth')", v-model="formObject.profile_birth_date")
        .section-block-3
          BaseInputText(:label="$t('builderPage.label_place_birth')", v-model="formObject.profile_birth_location")
        .section-block-3
          BaseInputText(:label="$t('builderPage.label_nationality')", v-model="formObject.profile_nationality")
        .section-block-3
          baseInputSelect(
            :listOptions="$tm('builderPage.genere_options')",
            v-model="$store.state.DataCvBuilderStore.personal_data.profile_genre",
            position="bottom",
            :label="$t('builderPage.label_genere')"
          )
        .section-block-3
          BaseInputText(:label="$t('builderPage.label_car_license')", v-model="formObject.profile_vehicle_permision")
        .section-block-3
          BaseInputText(:label="$t('builderPage.label_civil_state')", v-model="formObject.profile_civil_state")
        .section-block-3
          BaseInputText(:label="$t('builderPage.label_web_page')", v-model="formObject.profile_web")
        .section-block-3
          BaseInputText(:label="$t('builderPage.label_linkedin')", v-model="formObject.profile_linkedin")
        .section-block-3
          BaseInputText(:label="$t('builderPage.label_instagram')", v-model="formObject.profile_instagram")
    .mt-4
      .button-show-more(@click="showHideMoreOptions")
        .transform-gpu.transition.transition-transform.ease-in-out.duration-500(
          :class="{ '-rotate-180': showMoreOptions }"
        )
          BaseCustomIcon.icon(nameIcon="mdiChevronDown", :size="33")
        span {{ $t('builderPage.button_info_additional') }}
</template>

<script>
import FormWrapper from "../form-wrapper.vue";
import { ref } from "vue";
export default {
  name: "personal-data-component",
  inject: ["$ObserserFormSync"],
  components: {
    FormWrapper
  },
  data: () => ({}),
  computed: {
    formObject: {
      get() {
        return this.$store.state.DataCvBuilderStore.personal_data;
      },
      set(value) {
        this.$store.commit("DataCvBuilderStore/updatePofile", value);
      },
    },
    languageTemplate: {
      get() {
        return this.$store.state.DataCvBuilderStore.template_conf.lang;
      },
      set(value) {
        this.$store.commit("DataCvBuilderStore/updateTemplateLang", value);
      },
    },
  },
  watch: {
    formObject: {
      deep: true,
      handler() {
        this.$ObserserFormSync();
      },
    },
    languageTemplate: {
      deep: true,
      handler() {
        this.$ObserserFormSync();
      },
    },
  },
  setup() {
    const showMoreOptions = ref(false);
    function showHideMoreOptions() {
      showMoreOptions.value = !showMoreOptions.value;
    }
    return {
      showHideMoreOptions,
      showMoreOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  @apply py-4 px-8 mt-6;
}

.info-personal-grid {
  @apply grid grid-cols-1 flex-grow gap-4 sm:grid-cols-2;

  &.desktop {
    @apply hidden sm:grid;
  }
  &.mobile {
    @apply grid grid-cols-1 xs:grid-cols-2 sm:hidden;
  }
}

.section-block-direction {
  @apply col-span-6 xs:col-span-4;
}
.section-block-postal-code {
  @apply col-span-6 xs:col-span-2;
}

.section-block-3 {
  @apply col-span-6 xs:col-span-2;
}
.header-nav {
  @apply flex items-end;
  .icon {
    @apply text-blue-500;
  }
}
.button-show-more {
  @apply flex items-center cursor-pointer;

  .icon {
    @apply text-blue-400;
  }

  span {
    @apply pl-2 text-sm font-medium text-gray-500 transition-colors;
    &:hover {
      @apply text-blue-400;
    }
  }

  &::after {
    content: "";
    height: 2px;

    @apply bg-blue-100 flex-grow ml-4;
  }
}

.form {
  @apply py-4;
}

.box-extra-info {
  max-height: 0;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  @apply overflow-hidden flex gap-4 mt-4 w-full;

  &.expanded {
    max-height: 2000px;
    opacity: 1;
    @apply overflow-visible;
  }
}
</style>
