<template lang="pug">
.bottomOption(v-if="templateColors")
  .button-wrapper(@click="changeTemplate")
    .button {{ $t('builderViewer.change_template') }}
  formsBaseSelectColor(
    :options_colors="templateColors",
    :color_selected="colorsSelected",
    v-on:selectedColor="changeSelectedColor"
  )
  formsBaseSelectorFont(
    :font_selected="fontSelected",
    v-on:selectedFont="changeSelectedFont"
  )
</template>

<script>
export default {
  name: "bottom-options",
  inject: ["$ObserserFormSync"],
  data: () => ({}),
  computed: {
    templateColors() {
      return (
        this.$store.getters["TemplatesStore/getSelectedTemplate"]
          ?.option_colors || []
      );
    },
    colorsSelected() {
      const colorSelectedForm =
        this.$store.getters["DataCvBuilderStore/getColorsTempalte"];
      if (colorSelectedForm == null) {
        return (
          this.$store.getters["TemplatesStore/getSelectedTemplate"]
            ?.default_colors || {}
        );
      } else {
        return colorSelectedForm;
      }
    },
    fontSelected() {
      const fontSelectedForm =
        this.$store.getters["DataCvBuilderStore/getFontTemplate"];
      if (fontSelectedForm == null) {
        return "Roboto";
      } else {
        return fontSelectedForm;
      }
    },
  },
  methods: {
    changeSelectedColor(groupColors) {
      this.$store.commit("DataCvBuilderStore/updateColorSelected", groupColors);
      this.$ObserserFormSync();
    },
    changeSelectedFont(font) {
      this.$store.commit("DataCvBuilderStore/updateFontselected", font);
      this.$ObserserFormSync();
    },
    changeTemplate() {
      if (
        this.$store.getters["UserStore/getIsLogged"] &&
        this.$store.getters["DataCvBuilderStore/getCurriculumId"]
      ) {
        this.$router.push({
          name: "builder/select-template/with-id",
          params: {
            curriculum_id:
              this.$store.getters["DataCvBuilderStore/getCurriculumId"],
          },
        });
      } else {
        this.$router.push({ name: "builder/select-template" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottomOption {
  @apply grid grid-cols-3 h-full items-center gap-2 justify-center p-2;

  .button-wrapper {
    @apply flex h-full relative;
  }
  .button {
    @apply relative flex-grow h-full bg-blue-50 flex items-center justify-center rounded-md cursor-pointer transition-colors text-blue-400 border border-blue-400 text-center text-sm;
    &:hover {
      @apply bg-blue-100 text-blue-500;
    }
  }
}
</style>
