<template lang="pug">
.py-4.px-2
  FormsBaseTextArea(
    :content="form.section_description",
    v-on:update="updateDescription"
  )
</template>

<script>
import SectionHobbies from "@/store/models/hobbie.model";
import builderComponentsMixin from "../builder-components.mixin";
import BaseHeaderComponent from "./_base-header-component.vue";
export default {
  name: "hobbiesComponent",
  mixins: [builderComponentsMixin],
  components: {
    BaseHeaderComponent,
  },
  data: () => ({
    form: {
      section_description: "",
      section_position: 0,
    },
  }),
  methods: {
    async updateItem() {
      await SectionHobbies.update({
        where: (item) => {
          return item.id == this.propItem.id;
        },
        data: {
          ...this.form,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-study {
  @apply border-b border-blue-100 py-4;
}
.box-form {
  max-height: 0;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  @apply grid grid-cols-2 gap-4 overflow-hidden;

  &.expanded {
    max-height: 1000px;
    opacity: 1;
    @apply px-2 pt-4 overflow-visible;
  }
}
</style>
