<template lang="pug">
.py-4.px-2
  FormsBaseTextArea(
    :content="form.section_description",
    v-on:update="updateDescription"
  )
</template>


<script>
import SectionProfile from "@/store/models/profile.model";
import builderComponentsMixin from "../builder-components.mixin";

export default {
  name: "profileComponent",
  mixins: [builderComponentsMixin],
  data: () => ({
    form: {
      section_description: "",
      section_position: 0,
    },
  }),
  methods: {
    async updateItem() {
      await SectionProfile.update({
        where: (item) => {
          return item.id == this.propItem.id;
        },
        data: {
          ...this.form,
        },
      });
    },
  },
};
</script>

<style lang="scss">
</style>
